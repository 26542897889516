import React, { Component } from "react";
import "./App.css";
import Navbar from "./Components/Navbar";

import SectionMain from "./Components/SectionMain";
import SessionTimes from "./Components/SessionTimes";
import SectionParties from "./Components/SectionParties";
import TimeTable from "./TimeTable";
import navText from "./navText";
import $ from "jquery";
import Fade from 'react-reveal/Fade';
import SectionLearn from "./Components/SectionLearn";
import SectionHockey from "./Components/SectionHockey";
import SectionLocation from "./Components/SectionLocation";
import ExpressTable from "./ExpressTable";
import logo from "./Images/logo-drk.png";
import farewell from "./Images/farewell.jpeg";

class App extends Component {
  
  componentDidMount () {

    /*
    $(window).scroll(function() {

      
      if ($(this).scrollTop() > 0) {
        $('.middle').fadeOut();
      } else {
        $('.middle').fadeIn();
      }
        
    });

    */

    $( document ).ready(function() {
      $(".Sunday.box").show();
      $("#suBtn").addClass("selected");
  });

    $("#suBtn").click(function(){
      $(".Sunday.box").show();
      $(".Monday.box").hide();
      $(".Tuesday.box").hide();
      $(".Wednesday.box").hide();
      $(".Thursday.box").hide();
      $(".Friday.box").hide();
      $(".Saturday.box").hide();

      $("#suBtn").addClass("selected");
      $("#mBtn").removeClass("selected");
      $("#tBtn").removeClass("selected");
      $("#wBtn").removeClass("selected");
      $("#thBtn").removeClass("selected");
      $("#fBtn").removeClass("selected");
      $("#sBtn").removeClass("selected");
    });

    $("#mBtn").click(function(){
      $(".Sunday.box").hide();
      $(".Monday.box").show();
      $(".Tuesday.box").hide();
      $(".Wednesday.box").hide();
      $(".Thursday.box").hide();
      $(".Friday.box").hide();
      $(".Saturday.box").hide();

      $("#suBtn").removeClass("selected");
      $("#mBtn").addClass("selected");
      $("#tBtn").removeClass("selected");
      $("#wBtn").removeClass("selected");
      $("#thBtn").removeClass("selected");
      $("#fBtn").removeClass("selected");
      $("#sBtn").removeClass("selected");
    });

    $("#tBtn").click(function(){
      $(".Sunday.box").hide();
      $(".Monday.box").hide();
      $(".Tuesday.box").show();
      $(".Wednesday.box").hide();
      $(".Thursday.box").hide();
      $(".Friday.box").hide();
      $(".Saturday.box").hide();

      $("#suBtn").removeClass("selected");
      $("#mBtn").removeClass("selected");
      $("#tBtn").addClass("selected");
      $("#wBtn").removeClass("selected");
      $("#thBtn").removeClass("selected");
      $("#fBtn").removeClass("selected");
      $("#sBtn").removeClass("selected");
    });

    $("#wBtn").click(function(){
      $(".Sunday.box").hide();
      $(".Monday.box").hide();
      $(".Tuesday.box").hide();
      $(".Wednesday.box").show();
      $(".Thursday.box").hide();
      $(".Friday.box").hide();
      $(".Saturday.box").hide();

      $("#suBtn").removeClass("selected");
      $("#mBtn").removeClass("selected");
      $("#tBtn").removeClass("selected");
      $("#wBtn").addClass("selected");
      $("#thBtn").removeClass("selected");
      $("#fBtn").removeClass("selected");
      $("#sBtn").removeClass("selected");
    });

    $("#thBtn").click(function(){
      $(".Sunday.box").hide();
      $(".Monday.box").hide();
      $(".Tuesday.box").hide();
      $(".Wednesday.box").hide();
      $(".Thursday.box").show();
      $(".Friday.box").hide();
      $(".Saturday.box").hide();

      $("#suBtn").removeClass("selected");
      $("#mBtn").removeClass("selected");
      $("#tBtn").removeClass("selected");
      $("#wBtn").removeClass("selected");
      $("#thBtn").addClass("selected");
      $("#fBtn").removeClass("selected");
      $("#sBtn").removeClass("selected");
    });

    $("#fBtn").click(function(){
      $(".Sunday.box").hide();
      $(".Monday.box").hide();
      $(".Tuesday.box").hide();
      $(".Wednesday.box").hide();
      $(".Thursday.box").hide();
      $(".Friday.box").show();
      $(".Saturday.box").hide();

      $("#suBtn").removeClass("selected");
      $("#mBtn").removeClass("selected");
      $("#tBtn").removeClass("selected");
      $("#wBtn").removeClass("selected");
      $("#thBtn").removeClass("selected");
      $("#fBtn").addClass("selected");
      $("#sBtn").removeClass("selected");
    });

    $("#sBtn").click(function(){
      $(".Sunday.box").hide();
      $(".Monday.box").hide();
      $(".Tuesday.box").hide();
      $(".Wednesday.box").hide();
      $(".Thursday.box").hide();
      $(".Friday.box").hide();
      $(".Saturday.box").show();

      $("#suBtn").removeClass("selected");
      $("#mBtn").removeClass("selected");
      $("#tBtn").removeClass("selected");
      $("#wBtn").removeClass("selected");
      $("#thBtn").removeClass("selected");
      $("#fBtn").removeClass("selected");
      $("#sBtn").addClass("selected");
    });

  }

  render() {
    return (

      <div className="App" style={{overflowY: "scroll"}}>
        {/* 
        <Fade bottom>
          <div className="middle">
            <div class="mouse"/>
          </div>
        </Fade>
        */}


        <div className="farewellDiv">
          <img
              src={logo}
              className="nav-logo2"
              alt="Logo"
          />

          <p>
            <b>Farewell to Gold Coast Iceland</b><br/><br/>
            It is with heavy hearts that we officially announce the closure of Gold Coast Iceland. For decades, it’s been a home for skaters, hockey players, and ice sports fans of all ages. Gold Coast Iceland was not just a rink—it was a legacy, a place where we all made lifelong memories and formed many lifelong relationships.
            <br/><br/>
            Grahame and Sharon Sullivan established Iceland to become a cornerstone of our community, following in the footsteps of Sharon's father, Pat Burley, a true pioneer in the industry. Grahame carried Pat's vision forward from Victoria to the Gold Coast, creating a space where both beginners and seasoned athletes could thrive. The Burley family legacy in the ice skating world is unmatched, and through Iceland, they ensured the name remained synonymous with quality, passion, and innovation in ice sports.
            <br/><br/>
            Sharon Sullivan (Burley) was a true trailblazer in the world of ice sports and is inducted into the Ice Skating Hall of Fame. Sharon became a four-time Australian Ladies Champion, five-time World Championship competitor, and represented Australia in the Olympic Games. She also made her mark internationally as a star of the renowned American ice show, Ice Capades, where she performed for five years. Her accomplishments on and off the ice are a testament to her dedication, skill, and passion for the sport, and she has been an inspiration to skaters across Australia and beyond.
            <br/><br/>
            The decision to close Iceland was not an easy one. Rising insurance premiums and operating costs have forced the closure, and like many in the industry, we’ve faced challenges that were beyond resolution.
            <br/><br/>
            We want to thank everyone who has been part of our story—skaters, coaches, players, parents, and supporters. You have all helped make this rink the special place it was. As we close this chapter, we encourage everyone to share their favourite memories. Whether it was your first time on skates, an exciting hockey game, or a special moment with friends and family, we’d love to hear your stories and keep the Iceland spirit alive.
            <br/><br/><br/>
            Thank you all for your support,<br/>
            From the Sullivan family.
            <br/><br/>
            <img
              src={farewell}
              alt="farewell"
            />
          </p>
        </div>
        
        



        {/* 
        <Navbar/>
        <SectionMain
          messageTitle="COVID-19 Message:"
          message="Due to COVID-19, Iceland Management expects all customers to follow the social distancing recommendations issued by the 
          Queensland Government. We also require customers to use the 'Check-In QLD' app for Contact Tracing requirements. "
          message2="Thank you for your patience and understanding."
          dark={true}
          subtitle={navText}
          id="section1"
        />
        
        <SessionTimes
          title="Session Times & Prices"
          messageTitle="Information"
          message="Times are subject to change, please call 55399899 to confirm public session times during public holidays. Bookings are only required for groups of 20 or more."
          message2Title="What to Wear"
          message2="Warm clothing (long pants, jumper), socks are a must! Gloves are optional."
          childrenPrice="$20"
          studentPrice="$24"
          adultPrice="$30"
          expressPrice="$20"
          expressAdult="$25"
          table={TimeTable}
          expressTable={ExpressTable}
          dark={false}
          id="section2"
        />

        <SectionParties
          title="Children's Parties"
          messageTitle="Information"
          message="Iceland offers fun and exciting parties just for kids, all at a very low price! We run children’s parties every Saturday and Sunday."
          message2="Please note we only do one party per session (weekends only), so availability is limited. Please call us for more information."
          price="$35"
          dark={true}
          id="section3"
        />
        
        <SectionLearn
          price="$33"
          price2="$38"
          weekdaytime="4.00PM - 5.00PM"
          weekdaytime2="5.00PM - 6.00PM"
          weekendtime="9.30AM - 10.30AM"
          weekendtime2="4.00PM - 5.30PM"
          dark={false}
          id="section4"
        />

        <SectionHockey
          kidsprice="$25"
          kidstime="Tuesday 5.30PM - 6.30PM"
          adultsprice="$30"
          adultstime="Monday 5.40PM - 7.00PM"
          dark={true}
          id="section5"
        />
        <SectionLocation
          dark={true}
          id="section6"
        />
        */}
      </div>

      
    );
  }
}

export default App;
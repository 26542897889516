import React from 'react';
var early1 = "10.00AM - 11.00AM"
var early= "11.30AM - 12.30PM";
var midday = "1.00PM - 2.00PM";
var afternoonEx = "2.30PM - 3.30PM";
var later = "7:00PM - 8:00PM";
var later2 = "7:30PM - 8:30PM";
var morning= "10.30AM - 12.00PM";
var afternoon = "1.00PM - 2.30PM";
var evening1 = "7.00PM - 8.30PM";
var evening2 = "7.30PM - 9.00PM";
var evening3 = "7.00PM - 9.00PM";
var evening4 = "4.00PM - 5.30PM";

const ExpressTable =
    <div className="sessionTimes express">

    <div className="cal">
        <p>Select Day</p>
        <ul>
            <li><button className="dayBtn" id="suBtn"><span>Su</span></button></li>
            <li><button className="dayBtn" id="mBtn"><span>M</span></button></li>
            <li><button className="dayBtn" id="tBtn"><span>T</span></button></li>
            <li><button className="dayBtn" id="wBtn"><span>W</span></button></li>
            <li><button className="dayBtn" id="thBtn"><span>Th</span></button></li>
            <li><button className="dayBtn" id="fBtn"><span>F</span></button></li>
            <li><button className="dayBtn" id="sBtn"><span>Sa</span></button></li>
        </ul>
    </div>

    <table class="Sunday box">
        <tbody>
            <tr>
                <td>
                    <span>{morning}</span>
                    <br/>Weekend Session
                </td>
            </tr>
            <tr>
                <td>
                    <span>{afternoon}</span>
                    <br/>Weekend Session
                </td>
            </tr>
            {/*
            <tr>
                <td>
                    <span>{evening4}</span>
                    <br/>Public Session
                </td>
            </tr>
            */}
        </tbody>
    </table>

    <table class="Monday box">
        <tbody>
            <tr>
                <td>
                    <span>{early1}</span>
                    <br/>Express Session
                </td>
            </tr>
            <tr>
                <td>
                    <span>{early}</span>
                    <br/>Express Session
                </td>
            </tr>
            <tr>
                <td>
                    <span>{midday}</span>
                    <br/>Express Session
                </td>
            </tr>
            <tr>
                <td>
                    <span>{later}</span>
                    <br/>Express Session
                </td>
            </tr>
        </tbody>
    </table>

    <table class="Tuesday box">
        <tbody>
            <tr>
                <td>
                    <span>{early1}</span>
                    <br/>Express Session
                </td>
            </tr>
            <tr>
                <td>
                    <span>{early}</span>
                    <br/>Express Session
                </td>
            </tr>
            <tr>
                <td>
                    <span>{midday}</span>
                    <br/>Express Session
                </td>
            </tr>

            <tr>
                <td>
                    <span>{later}</span>
                    <br/>Express Session
                </td>
            </tr>
        </tbody>
    </table>

    <table class="Wednesday box">
        <tbody>
            <tr>
                <td>
                    <span>{early1}</span>
                    <br/>Express Session
                </td>
            </tr>
            <tr>
                <td>
                    <span>{early}</span>
                    <br/>Express Session
                </td>
            </tr>
            <tr>
                <td>
                    <span>{midday}</span>
                    <br/>Express Session
                </td>
            </tr>

            <tr>
                <td>
                    <span>{later}</span>
                    <br/>Express Session
                </td>
            </tr>
        </tbody>
    </table>

    <table class="Thursday box">
        <tbody>
            <tr>
                <td>
                    <span>{early1}</span>
                    <br/>Express Session
                </td>
            </tr>
            <tr>
                <td>
                    <span>{early}</span>
                    <br/>Express Session
                </td>
            </tr>
            <tr>
                <td>
                    <span>{midday}</span>
                    <br/>Express Session
                </td>
            </tr>

        </tbody>
    </table>

    <table class="Friday box">
        <tbody>
            <tr>
                <td>
                    <span>{early1}</span>
                    <br/>Express Session
                </td>
            </tr>
            <tr>
                <td>
                    <span>{early}</span>
                    <br/>Express Session
                </td>
            </tr>
            <tr>
                <td>
                    <span>{midday}</span>
                    <br/>Express Session
                </td>
            </tr>

            <tr>
                <td>
                    <span>{evening3}</span>
                    <br/>Weekend Session
                </td>
            </tr>
        </tbody>
    </table>

    <table class="Saturday box">
        <tbody>
            <tr>
                <td>
                    <span>{morning}</span>
                    <br/>Weekend Session
                </td>
            </tr>
            <tr>
                <td>
                    <span>{afternoon}</span>
                    <br/>Weekend Session
                </td>
            </tr>
            <tr>
                <td>
                    <span>{evening3}</span>
                    <br/>Weekend Session
                </td>
            </tr>
        </tbody>
    </table>

    </div>;

export default ExpressTable;